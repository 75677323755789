// Modal overrides


.modal-dialog {
    max-width: 748px;
    margin: 1.75rem auto;
    padding: 0 20px;
    h2 {
        margin: 10px 0;
        font-size: 1.6em !important;
        @media screen and (min-width: $screen-md) {
            font-size: 1.8em !important;
        }
    }
    h3 {
        margin: 10px 0;
        font-size: 1.4em !important;
        @media screen and (min-width: $screen-md) {
            font-size: 1.6em !important;
        }
    }
    p {
        margin: 10px 0;
    }
    .underline {
        height: 1px;
        background: #000;
        margin-top: 30px;
        margin-bottom: 10px;
    }
    table {
        width: 100%;
    }
    .center {
        text-align: center;
    }
}



