// Footer styles

footer {
    background-color: rgba($color: #efefef, $alpha: .3);
    .inside-xxl {
        padding-top: 20px;
        padding-bottom: 20px;
    }
}

.ms-footer__list {
    display: flex;
    justify-content: center;
}

footer .ms-footer__list.social .ms-footer__link div, footer .ms-footer__list.social .ms-footer__link__text {
    display: none;
}

footer .ms-footer__list.social .ms-footer__link {
    width: 48px;
    height: 48px;
}

footer {
    .ms-footer__list.not-social {
        .ms-footer__item {
            display: inline-block;
            padding: 2px 5px;
        }
    }
    .ms-footer__list.social {
        .ms-footer__item {
            padding: 5px;
        }
        .ms-footer__link {
            border: 2px solid #000;
            border-radius: 100%;
        }
        .ms-footer__link::after {
            content: '';
            
            background-repeat: no-repeat;
            background-position: center;
            background-size: 24px 24px;
            width: 48px;
            height: 48px;
            position: relative;
            top: -2px;
        
        }
        .facebook {
            .ms-footer__link::after {
                background-image: url("../../../connelly/img/facebook-f-brands.svg");
                background-size: 20px 20px;
            }
        }
        .instagram {
            .ms-footer__link::after {
                background-image: url("../../../connelly/img/instagram-brands.svg");
            }
        }
    }
    
    
}