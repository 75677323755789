.ms-breadcrumb {
    padding-top: 20px;
}

.ms-buybox {
    padding-bottom: 20px;
}

.ms-breadcrumb .ms-breadcrumb_item {
    font-family: inherit;
}

.ms-buybox .msc-price__actual {
    font-family: inherit;
}

.ms-buybox__product-title {
    font-family: $bodyText;
    line-height: 1.3;
}

.ms-buybox__product-description {
    font-family: $bodyText;
    font-weight: 400;
}

.ms-buybox__product-description {
    p,
    ul {
        margin: 10px 0;
    }
}

.ms-buybox__dropdown-quantity-label {
    font-family: $bodyText;
    font-weight: 400;
}

.ms-buybox .msc-dropdown__select {
    font-family: $bodyText;
    font-weight: 400;
    border: 1px solid $textDefaultColor;
}

.ms-buybox__product-quantity-label {
    font-family: $bodyText;
    font-weight: 400;
}

.ob-summary {
    align-items: flex-end;
}

.ms-buybox .ms-buybox__quantity input {
    width: 58px;
    height: 48px;
    border: 1px solid #6b727a;

    padding: 0 0 0 8px;
}

.ms-buybox__add-to-cart-container {
    position: static;
    flex: 1;
    margin-right: 5px;
}
.msc-add-to-cart {
    background-color: $accentColor;
    border: 1px solid $accentColor;
    transition: all 0.3s ease;
    height: 48px;
    width: 100%;
}

.msc-add-to-cart:hover,
.msc-add-to-cart:focus {
    background-color: $textDefaultColor;
    border: 1px solid $textDefaultColor;
}

.msc-add-to-wishlist {
    height: 48px;
    width: 48px;
    background: #fff;
    border: 1px solid;
}

.ms-buybox__add-to-cart-container,
.ms-quickView__add-to-cart-container {
    position: static;
    flex: 1;
    margin-right: 5px;
}

.ms-quickView__add-to-cart-container {
    border-radius: 0;
    display: block;
    min-width: none;
}

.ms-quickView__add-to-wishlist-container {
    display: block;
    position: static;
    .msc-add-to-wishlist {
        height: 48px;
        position: static;
    }
}

.ms-quickView__add-to-cart-container .msc-add-to-cart {
    height: 48px;
    width: 100%;
    min-width: none;
    width: 100%;
    position: static;
    min-width: none !important;
}

.msc-add-to-wishlist,
.msc-add-to-order-template-icon {
    height: 48px;
    width: 48px;
    background: #fff;
    border: 1px solid #000;
    margin-right: 5px;
}

.ms-media-gallery .ms-media-gallery__carousel .msc-carousel__inner .msc-carousel__item {
    max-height: none !important;
}

.msc-modal .msc-add-to-order-template-dialog__empty__dialog__create-order-template-button {
    background-color: $accentColor;
    border: 1px solid $accentColor;
}
.msc-modal .msc-add-to-order-template-dialog__empty__dialog__create-order-template-button:hover,
.msc-modal .msc-add-to-order-template-dialog__empty__dialog__create-order-template-button:focus {
    background-color: $textDefaultColor;
    border: 1px solid $textDefaultColor;
}

.msc-modal .msc-order-template-name-dialog__dialog__create-order-template-button {
    background-color: $accentColor;
    border: 1px solid $accentColor;
}
.msc-modal .msc-order-template-name-dialog__dialog__create-order-template-button:hover,
.msc-modal .msc-order-template-name-dialog__dialog__create-order-template-button:focus {
    background-color: $textDefaultColor;
    border: 1px solid $textDefaultColor;
}

.ms-buybox__product-title {
    font-weight: 600;
}

.ms-quickView__dropdown {
    display: flex;
    label {
        flex: 0 0 100px;
    }
}

.msc-buybox__ratings-section-sku-text {
    color: #9e9e9e;
    margin-top: 10px;
}