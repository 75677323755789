// for the search autosuggest module

.ms-search__autoSuggest {
    top: 135px;
    margin-top: 0;
    background: rgba($color: #000000, $alpha: .8);
    color: #fff !important;
    @media screen and (min-width: $screen-md) {
        max-width: 1660px;
        width: 100%;
        left: 0;
        display: flex;
        justify-content: space-around;
    }
    .ms-search__autoSuggest__product, .ms-search__autoSuggest__category {
        flex: 0 0 48%;
        box-sizing: border-box;
        padding: 10px;
    }
    .ms-search__autoSuggest__keyword, .ms-search__autoSuggest__product, .ms-search__autoSuggest__category {
        border-top: none;
        border-right: none;
        border-bottom: none;
        border-left: none;

    }
    .msc-autoSuggest__categoryResults-title, .msc-autoSuggest__productResults-title {
        color: #fff;
        padding: 16px 0 8px;
    }
    .msc-autoSuggest__loadingResult {
        padding: 13px 0;
    }
    .ms-search__autoSuggest__keyword {
        display: none;
    }
    // .msc-autoSuggest__productResults-item {
    //     display: flex;
    //     flex-direction: column;
    //     justify-content: center;
    // }
    a.msc-autoSuggest__productResults-item {
        display: flex;
        margin: 10px 0;
        color: #fff;
        grid-gap: 5px;
        .msc-image-container {
            margin: 0;
            > span {
                display: block;
                padding: 0 5px;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
        }
    }
    .msc-autoSuggest__productResults-item-price {
        color: #fff;
    }
    .msc-autoSuggest__productResults-item :hover, .msc-autoSuggest__categoryResults-item :hover {
        background-color: transparent;
    }

    .msc-autoSuggest__categoryResults-item a {
        color: #fff;
    }

}

