// overrides

.ms-nav {
    border: none;
    background: #fff;
    .ms-nav {
        > &__list {
            background: #fff;
        }
    }
}

// desktop navigation
.desktop-nav .ms-nav {
    display: none;
    @media screen and (min-width: $msv-breakpoint-l) {
        display: block;
    }
}

.ms-nav > .ms-nav__list > .ms-nav__list__item {
    margin-top: 0;

    @media screen and (min-width: $msv-breakpoint-l) {
        max-height: none;
    }
}

.ms-header__mobile-hamburger-menu-links {
    .ms-nav.desktop-vp {
        display: block !important;
        //@media screen and (min-width: $msv-breakpoint-l) {
        //    display: none !important;
        //}
    }

    .ms-nav__list__item__button,
    .ms-nav__list__item__link {
        //display: block;
        padding: 5px 20px;
        box-sizing: border-box;
        position: relative;
        padding: 5px 20px;
        border-bottom: 2px solid #fff;
        height: auto;
        display: block;
        
    }
    .ms-nav__list__item__button::after {
        position: absolute;
        right: 20px;
    }
    .ms-nav__list__item > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__link {
        line-height: 35px;
        background-color: #f8f7f6;
    }
    
}

.ms-nav > .ms-nav__list .ms-nav__list {
    height: 100%;
    @media screen and (min-width: $msv-breakpoint-l) {
        height: auto;
    }
    min-height: 400px;
}

.ms-header__container .ms-header__collapsible-hamburger .ms-nav .ms-nav__list__item__link {
    padding: 5px 20px;
}

.ms-nav > .ms-nav__list > .ms-nav__list__item {
    margin: 0 !important;
    background: none;
}

.ms-nav__list__item__button,
.ms-nav__list__item__link {
    height: 100%;
    display: flex;
    align-items: center !important;
}

.ms-header .ms-nav.desktop-vp {
    height: 100%;
}

.ms-nav__list__item {
    font-weight: 300;
    font-family: $bodyText;
}


.ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__button,
.ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__link {
    font-weight: 400;
    font-family: $bodyText;
    @media screen and (min-width: $msv-breakpoint-l) {
        padding: 0 10px;
    }
    //text-transform: uppercase;
    //letter-spacing: 1px;
    //font-size: 0.9em;
    //background: #fff;
    @media screen and (min-width: $msv-breakpoint-l) {
       // max-height: 75px;
    }
}

.ms-header__container .ms-header__collapsible-hamburger .ms-nav .ms-nav__list__item__link {
    font-family: $bodyText;
    font-size: 1.2em;
}


.ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__button::after {
    transition: transform 0.3s ease;
    @media screen and (min-width: $msv-breakpoint-l) {
        transform: rotate(-90deg);
    }
    font-size: 0.8em;
    margin-top: -2px;
}

.ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__button[aria-expanded='true'] {
    background: #f8f7f6;
}



.ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__button[aria-expanded='true']::after {
    transform: rotate(90deg);
    @media screen and (min-width: $msv-breakpoint-l) {
        transform: rotate(0deg);
    }
}

.ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list {
    background: #fff;
    //max-height: 75px;
}

.ms-nav__list__item__button,
.ms-nav__list__item__link {
    //background: #fff;
    @media screen and (min-width: $msv-breakpoint-l) {
        max-height: 75px;
    }
}

.ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__button:hover::before,
.ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__button:focus::before,
.ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__link:hover::before,
.ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__link:focus::before {
    background-color: transparent;
}

.ms-nav > .ms-nav__list {
    background: #fff;
    @media screen and (min-width: $msv-breakpoint-l) {
        max-height: 75px;
    }
}




// 2022 new styles

.ms-header .ms-header__collapsible-hamburger {
    display: unset;
}


.ms-header .ms-header__nav-icon {
    background: #fff;
    @media screen and (min-width: $msv-breakpoint-l) {
        display: flex;
    }
}

.ms-header .msc-cart-icon {
    padding-left: 40px;
}

.ms-header .ms-header__nav-icon[aria-expanded='true']::before {
    content: $msv-Cancel;
}

.ms-header {

    .ms-header__collapsible-hamburger {
        padding-top: 48px;
        z-index: 2;
        //background: #fff;
        background: rgba($color: #000000, $alpha: .8);
        position: fixed;
        margin-top: 0;
        top: 0; right: 0; bottom: 0; left: 0;
        opacity: 0;
        visibility: hidden;
        height: 100%;
        min-height: auto !important;
        .ms-header__mobile-hamburger-menu-links {
            background: #fff;
            height: 100%;
            left: -100%;
            position: relative;
            transition: transform .6s ease;
            overflow: scroll;
            @media screen and (min-width: $msv-breakpoint-l) {
                overflow: visible;
                transition: transform .4s ease;
                width: 50%;
                left: -50%;
            }
        }
    }
    
    .ms-header__collapsible-hamburger.show {
        opacity: 1;
        visibility: visible;
        .ms-header__mobile-hamburger-menu-links {
            transform: translateX(100%);
            
        }
    }
    
}

.ms-header__container .ms-header__collapsible-hamburger .ms-nav .ms-nav__list__item__link {
    line-height: 45px;
}



.right-here {
    padding: 20px;
    height: 100%;
    background: #fff;
    > nav {
        overflow: scroll;
    }
}





.ms-nav > .ms-nav__list {
    max-height: none;
    background: none;
    display: block;
    position: static;
    .ms-nav__list__item {
        .submenu-test {
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            position: relative;
            @media screen and (min-width: $msv-breakpoint-l) {
                position: absolute;
            }
            ul {
                //background-color: rgba($color: #000000, $alpha: .5);
                position: relative;
                z-index: 100;
            }
            .submenu-background {
                    background-color: rgba($color: #000000, $alpha: .5);
                    position: absolute;
                    top: 0; right: 0; bottom: 0; left: 0;
            }
        }
    }    
    .ms-nav__list__item:nth-child(1) {
        .submenu-test {
            background-image: url('https://images-us-prod.cms.commerce.dynamics.com/cms/api/nwtfklkdlc/imageFileData/MAcQ44?pubver=1');
        }
    }
    .ms-nav__list__item:nth-child(2) {
        .submenu-test {
            background-image: url('https://images-us-prod.cms.commerce.dynamics.com/cms/api/nwtfklkdlc/imageFileData/MAcNt7?pubver=1');
        }
    }
    .ms-nav__list__item:nth-child(3) {
        .submenu-test {
            background-image: url('https://images-us-prod.cms.commerce.dynamics.com/cms/api/nwtfklkdlc/imageFileData/MAcNt4?pubver=1');
        }
    }
    .ms-nav__list__item:nth-child(4) {
        .submenu-test {
            background-image: url('https://images-us-prod.cms.commerce.dynamics.com/cms/api/nwtfklkdlc/imageFileData/MAcKRr?pubver=1');
        }
    }
    .ms-nav__list__item:nth-child(5) {
        .submenu-test {
            background-image: url('https://images-us-prod.cms.commerce.dynamics.com/cms/api/nwtfklkdlc/imageFileData/MAcNt1?pubver=1');
        }
    }
    .ms-nav__list__item:nth-child(6) {
        .submenu-test {
            background-image: url('https://images-us-prod.cms.commerce.dynamics.com/cms/api/nwtfklkdlc/imageFileData/MAcQ47?pubver=1');
        }
    }
    .ms-nav__list__item:nth-child(7) {
        .submenu-test {
            background-image: url('https://images-us-prod.cms.commerce.dynamics.com/cms/api/nwtfklkdlc/imageFileData/MAcQ4a?pubver=1');
        }
    }
    .ms-nav__list__item:nth-child(8) {
        .submenu-test {
            background-image: url('https://images-us-prod.cms.commerce.dynamics.com/cms/api/nwtfklkdlc/imageFileData/MAcIg3?pubver=1');
        }
    }
    .ms-nav__list__item:nth-child(9) {
        .submenu-test {
            background-image: url('https://images-us-prod.cms.commerce.dynamics.com/cms/api/nwtfklkdlc/imageFileData/MAcQ4d?pubver=1');
        }
    }
    .ms-nav__list__item:nth-child(10) {
        .submenu-test {
            background-image: url('https://images-us-prod.cms.commerce.dynamics.com/cms/api/nwtfklkdlc/imageFileData/MAcNsV?pubver=1');
        }
    }
    .ms-nav__list__item:nth-child(11) {
        .submenu-test {
            background-image: url('https://images-us-prod.cms.commerce.dynamics.com/cms/api/nwtfklkdlc/imageFileData/MAcNsY?pubver=1');
        }
    }
    
}

.ms-nav__list__item__button, .ms-nav__list__item__link {
    background-color: transparent;
}

.ms-nav {
    background: none;
}

.ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__button, .ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__link {
    //font-size: 1em !important;
    padding: 5px 20px 5px 5px;
    background: none;
    display: block;
    border: 1px solid transparent;
    border-bottom: 1px solid #000;
    font-size: 1.2em !important;
}

.ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__button::after {
    margin-top: 0;
    
}
.ms-header__container .ms-header__collapsible-hamburger .ms-nav.mobile-vp {
    padding-top: 0;
}

.ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__button::after {
    margin: 0;
    @media screen and (min-width: $msv-breakpoint-l) {
        content: '\f061';
        transform: rotate(0);
        font-weight: 900;
        transition: all .4s ease;
        opacity: .3;
    }
}

.ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__button[aria-expanded="true"] {
    background: none;
}

.ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__button:hover::after {
    @media screen and (min-width: $msv-breakpoint-l) {
        transform: translateX(15px);
        opacity: 1;
    }
}

.ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__button[aria-expanded="true"]::after {
    @media screen and (min-width: $msv-breakpoint-l) {
        transform: translateX(15px);
        opacity: 1;
    }
}

.ms-nav > .ms-nav__list > .ms-nav__list__item > .submenu-test {
   
    @media screen and (min-width: $msv-breakpoint-l) {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: -1;
        transform: translateX(0);
        transition: transform .4s ease;
        overflow: scroll;
        background-color: #000;
        ul {
            width: 100%;
        }
    }
}

.ms-nav > .ms-nav__list > .ms-nav__list__item > .ms-nav__list__item__button[aria-expanded="true"] + .submenu-test  {
    @media screen and (min-width: $msv-breakpoint-l) {
        transform: translateX(100%);
    }
}



.submenu-test {
    > ul {
        padding: 20px;
    }
    .ms-nav__list__item__link {
        
            color: #fff !important;
            background-color: transparent;
        
    }

}