// Added for general grid settings.

img {
  // added to fix image vertical stretch.
  height: auto;
}

.align-center {
  // added to add center align. You my use bootstrap to do this if you want.
  text-align: center;
}
