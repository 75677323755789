// Video styles

.stable-video {
    height: 750px;
}


.bgvideo {
    position: relative;
    //padding-top: 56.25%;
    min-height: 750px;
    .react-player {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .cn-title {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 10;
        h1 {
            color: #fff;
            font-style: italic;
        }
    }
}

.video-caption.ms-text-block {
    margin-top: 0;
    padding: 10px;
    margin-bottom: 25px;
}

.photo-caption {
    padding: 10px 0;
}


.video-height {
    > div:first-child {
        height: 750px;
    }
}