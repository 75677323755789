;
@import 'bootstrap/scss/bootstrap';

// call to the fabrikam design kit
@import '@msdyn365-commerce-modules/fabrikam-design-kit/src/modules/fabrikam/styles/fabrikam.theme.scss';

$bodyText: 'Montserrat', sans-serif;


$accentColor: #555555;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;1,300;1,400;1,500;1,600&display=swap');

body {
    font-family: $bodyText;
    font-size: 16px;
    line-height: 20px;
}

// primary variables
$brandPrimaryColor: #ffffff;
$brandSecondaryColor: #cccccc;
$backgrounDefaultColor: #ffffff;
$b2bGray: #f5f5f5;
$b2bDkGray: #555555;
$textDefaultColor: #110e0e;
$bodyFont: Arial;
$displayFont: Impact;
$borderRadius: 4;
$iconStyle: Line;
$categoryColor: #000;
$expandedCategoryColor: #000;

:root {
    --brandPrimaryColor: #{brandPrimaryColor};
    --brandSecondaryColor: #{brandSecondaryColor};
    --backgroundDefaultColor: #{$backgrounDefaultColor};
    --textDefaultColor: #{textDefaultColor};
    --bodyFont: #{bodyFont};
    --displayFont: #{displayFont};
    --borderRadius: #{borderRadius};
    --iconStyle: #{iconStyle};
    --categoryColor: #{categoryColor};
    --expandedCategoryColor: #{expandedCategoryColor};
    --msv-cart-primary-btn-bg: #{$categoryColor};
    --msv-cart-primary-btn-border: #{$categoryColor};
}

body {
    background-color: var(--backgroundDefaultColor);
}



// imports of sass sub folders
@import 'settings/index';
@import 'tools/index';
@import 'modules/index';


#main {

    .msc-btn {
        background-color: rgba($color: #000000, $alpha: 0) !important;
        border-color: #000 !important;
        font-family: $bodyText !important;
        text-transform: uppercase !important;
        color: #000 !important;
        transition: all .3s ease;

    }

    .msc-btn:hover, .msc-btn:focus {
        background-color: rgba($color: #000000, $alpha: 1) !important;
        color: #fff !important;
        text-decoration: none;
    }

}

