.arbor-padding {
    padding-top: 20px;
    padding-bottom: 20px;
}

.ms-search-result-container {
    padding: 40px 0;
}

.ms-search-result-container__refiner-section button.ms-refine-submenu__toggle_collapsed,
.ms-search-result-container__refiner-section .ms-refine-submenu__toggle_expanded {
    font-family: $bodyText;
}

.ms-search-result-container__Sort-by-category {
    font-family: $bodyText;
    padding: 10px;
    position: relative;
}
.ms-search-result-container__Sort-by-category .msc-l-dropdown {
    font-family: $bodyText;
    border: 1px solid #000;
}

.ms-search-result-container__Sort-by-category .msc-l-dropdown__label {
    margin: 0 10px;
}

//.ms-search-result-container__category-nav-section {
//    float: right;
//    margin-top: 0;
//}

.ms-quickView__button {
    border: 1px solid $b2bDkGray;
}
.ms-quickView__button:hover,
.ms-quickView__button:focus {
    background-color: $b2bGray;
}

.ms-quickView__product-title {
    line-height: normal;
    font-size: 1.5em !important;
}

.ms-quickView__body .msc-price__actual,
.ms-quickView__configure {
    font-family: $bodyText;
    font-size: inherit;
}

.ms-quickView__dropdown-quantity-label,
.ms-quickView__body .msc-dropdown__select {
    font-family: $bodyText;
}

.ms-quickView__body .msc-dropdown__select {
    font-size: inherit;
    border: 1px solid #000;
}

.ms-quickView__dropdown {
    margin-top: 10px;
}

.ms-quick-order__content-form-main__dropdown-quantity-label {
    margin-bottom: 0;
}

.ms-refine-submenu.Rating {
    display: none !important;
}

.ms-refine-submenu.ColorStd {
    > .msc-btn {
        visibility: hidden;
    }
    > .msc-btn::before {
        content: 'Color';
        visibility: visible;
    }
    > .msc-btn::after {
        visibility: visible;
    }
}

.ms-refine-submenu.SizeStd {
    > .msc-btn {
        visibility: hidden;
    }
    > .msc-btn::before {
        content: 'Size';
        visibility: visible;
    }
    > .msc-btn::after {
        visibility: visible;
    }
}

.ms-refine-submenu.StyleStd {
    > .msc-btn {
        visibility: hidden;
    }
    > .msc-btn::before {
        content: 'Quality';
        visibility: visible;
    }
    > .msc-btn::after {
        visibility: visible;
    }
}

// Quick order

.ms-quick-order__drawer {
    margin-top: 0;

    .drawer__button {
        padding-left: 20px;
        padding-right: 20px;
        position: relative;
        .ms-quick-order__heading {
            line-height: normal;
            margin-left: 0;
        }
    }
}

.ms-quick-order__drawer .drawer__button[aria-expanded='false']::after {
    position: absolute;
    right: 10px;
    top: 10px;
}

.ms-quick-order__drawer .drawer__button[aria-expanded='true']::after {
    position: absolute;
    right: 10px;
    top: 10px;
}

.ms-quick-order__drawer .drawer__button[aria-expanded='true']::after {
    line-height: normal;
}

.ms-quick-order__heading {
    line-height: normal;
    margin-left: 0;
}
.ms-quick-order__description {
    margin-left: 0;
}

.ms-quick-order__drawer .drawer__button::after {
    color: #000;
}

.btn-secondary:focus,
.btn-secondary.focus {
    box-shadow: none !important;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
    background-color: transparent;
}

.ms-quick-order__drawer {
    background: $b2bGray;
}

.ms-quick-order__drawer .drawer__button {
    border: none !important;
    background: none;
}

.ms-quick-order__content {
    border-top: 0;
    background-color: transparent;
}

.ms-quick-order__content-form-main-product-lable,
.ms-quick-order__content-form-main__dropdown-quantity-label,
.ms-quick-order__content-form-main__dropdown-quantity-label {
    font-family: $bodyText !important;
    font-size: 1.1em !important;
    line-height: normal;
    font-weight: 400;
    height: auto;
}
.ms-quick-order__content-form-main-product-lable {
    margin-bottom: 0;
}
.ms-quick-order__content-form-main-quantity-lable {
    font-family: $bodyText !important;
    font-size: 1.1em !important;
    line-height: normal;
    font-weight: 400;
    height: auto;
}

.ms-quick-order__content-form-main-addtocart-button {
    background-color: $b2bDkGray;
    border: $b2bDkGray;
    margin-top: 26px;
}
.ms-quick-order__content-form-main-addtocart-button:hover,
.ms-quick-order__content-form-main-addtocart-button:focus {
    background-color: $textDefaultColor;
    border: $textDefaultColor;
}

.msc-order-summary__line-sub-total {
    padding: 10px 0 10px;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
    box-shadow: none;
}
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
    background-color: transparent;
}

.ms-quick-order__content-form-main-product {
    height: auto;
}

.ms-quick-order__content-form-main-quantity-lable {
    line-height: normal;
}


// New list mode for category pages... 
.list-mode {
    .ms-search-result-container__Products .list-unstyled {
        display: block;
    }
    .ms-search-result-container__Products {
        li.ms-product-search-result__item {
            padding: 10px;
            text-align: center;
            @media screen and (min-width: $screen-xxs) {
                text-align: left;
                display: flex;
                justify-content: space-between;
            }
            .msc-product {
                display: block;
                @media screen and (min-width: $screen-xxs) {
                    display: flex;
                    flex: 1;
                    
                    &__image {
                        flex: 0 0 85px;
                    }
                }
            }
        }
    }
    

    .ms-search-result-container__Products {
        li:nth-child(odd) {
            background: $b2bGray;
        }
    }
    

    .ms-search-result-container .ms-product-search-result__item .msc-product .msc-image-container {
        width: 85px;
        height: 85px;
    }

    .ms-search-result-container .ms-product-search-result__item .msc-product .msc_image {
        width: 85px;
        height: 85px;
    }

    .ms-product-search-result__item a .msc-product__title {
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 10px;
    }

    .msc-product__sku {
        padding: 0 10px;
        color: #9e9e9e;
    }

    .msc-product__details {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .ms-quickView {
        position: static;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 2.5px;
        padding: 0;
        &__button {
            display: block;
            flex-grow: 0;
            min-width: auto;
            height: auto;
            font-family: $bodyText;
            padding: 6px 10px;
            width: auto;
        }
    }

    .ms-search-result-container__Products {
        margin-top: 10px;
    }

    .ms-search-result-container .msc-button {
        float: none;
    }
    
}

.connellyButton {
    margin: 2.5px;
    text-align: center;
    font-family: $bodyText;
    a {
        display: block;
        border: 1px solid #555;
        padding: 6px 10px;
        background: #555;
        color: #fff;
        transition: background .3s ease;
    }
    a:hover {
        background: #000;
    }
}

#main {

    .ms-search-result-container__refiner-section button.ms-refine-submenu__toggle_collapsed.msc-btn, 
    .ms-search-result-container__refiner-section .ms-refine-submenu__toggle_expanded.msc-btn {
        border-color: transparent !important;
    }

    .ms-search-result-container__refiner-section button.ms-refine-submenu__toggle_collapsed.msc-btn:hover, 
    .ms-search-result-container__refiner-section .ms-refine-submenu__toggle_expanded.msc-btn:hover,
    .ms-search-result-container__refiner-section button.ms-refine-submenu__toggle_collapsed.msc-btn:focus, 
    .ms-search-result-container__refiner-section .ms-refine-submenu__toggle_expanded.msc-btn:focus {
        background-color: rgba($color: #000000, $alpha: 0) !important;
        color: #000 !important;
    }

}



// quick order override 

#main {
    .ms-quick-order__drawer {
        .msc-btn:hover, .msc-btn:focus {
            background-color:rgba(0,0,0,0) !important;
        }
    } 
}

.ms-quick-order__content-form-main-product-lable, .ms-quick-order__content-form-main__dropdown-quantity-label, .ms-quick-order__content-form-main__dropdown-quantity-label {
    margin: 0 0 3px 0;
    display: block;
}



.ms-quick-order__content-form-main-addtocart-button {
    border: 1px solid #000;
}

.ms-quick-order__content-form-main-addtocart-button:hover {
    color: #000;
}


// Category Refiner Removals and edits.

.ms-refine-submenu.Category, .ms-refine-submenu.StyleStd, .ms-refine-submenu.Color {
    display: none !important;
}

// edits for 9.42

.ms-product-search-result__item:hover .ms-quickView__button, .ms-product-search-result__item:focus .ms-quickView__button, .ms-product-search-result__item:active .ms-quickView__button {
    font-family: $bodyText;
    min-width: auto;
    padding: 6px 10px;
    height: 36.4px;
    flex-grow: initial;
    font-size: 1em;
    font-weight: 400;
    background-color: transparent;
}