// Fonts

body {
    font-family: $bodyText;
    font-size: 16px;
    line-height: 1.4 !important;
}

h1,
h2,
h3,
h4 {
    font-family: $bodyText !important;
    line-height: 1.4 !important;
    font-weight: 600;
    text-transform: uppercase;
    font-style: italic;
}

h1 {
    font-size: 2.5em !important;
    @media screen and (min-width: $screen-md) {
        font-size: 3em !important;
    }
}

h1 {
    font-weight: normal;
    margin-top: 32px;
    margin-bottom: 20px !important;
}

h1.ms-buybox__product-title {
    font-size: 1.5em !important;
    @media screen and (min-width: $screen-md) {
        font-size: 2em !important;
    }
}

h2 {
    font-size: 2.2em !important;
    @media screen and (min-width: $screen-md) {
        font-size: 2.7em !important;
    }
}

h3 {
    font-size: 1.9em !important;
    @media screen and (min-width: $screen-md) {
        font-size: 2.4em !important;
    }
}

h4 {
    font-size: 1.2em !important;
    margin-top: 20px;
}

.ms-search-result-container__title .ms-search-result__collection-title-prefix, .ms-search-result-container__title .ms-search-result__collection-title-text {
    font-weight: unset;
}

.ms-product-search-result__item a .msc-product__title {
    font-weight: 600;
}
