// Search Results Container

.ms-search-result-container__title .ms-search-result__collection-title-prefix,
.ms-search-result-container__title .ms-search-result__collection-title-text {
    font-family: unset;
    font-size: unset;
}

.ms-search-result-container__category-nav-section .ms-search-result-container__category-hierarchy {
    font-family: unset;
}

.ms-search-result-container__category-nav-section .ms-search-result-container__title h5 .ms-search-result__collection-title-count {
    font-family: unset;
}

.ms-refine-submenu__toggle_expanded {
    font-family: unset;
}

.ms-product-search-result__item a .msc-product__title {
    font-family: inherit;
    font-size: unset;
    line-height: unset;
}
