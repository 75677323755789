// Homepage styles

.home-logo-center {
    picture {
        display: inline-block;
        margin-top: 50px;
        margin-bottom: 50px;
    }
}

.double-up {
    .row {
        justify-content: center;
    }
    .col-md-6 {
        overflow: hidden;
        border: 10px solid transparent;
    }
    .ms-content-block {
        min-height: 600px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .ms-content-block__details {
            position: relative;
            z-index: 10;
            color: #fff;
        }
        .ms-content-block__image {
            position: absolute;
            background: #000;
            min-height: 600px;
            overflow: hidden;
            transform: scale(1.0);
            transition: transform .3s ease;
            img {
                transition: opacity .3s ease;
                opacity: .3;
            }
        }
    }
    .ms-content-block:hover {
        .ms-content-block__image {
            transform: scale(1.2);
            img {
                opacity: .4;
            }
        }
    }
}

.ms-content-block__cta {
    .msc-cta__primary {
        
        padding: 14px 22px;
        background: #fff;
        border: 2px solid #000;
        text-transform: uppercase;
        font-size: 1.2em;
    }
    .msc-cta__primary:hover {
        color: #000;
        text-decoration: none;
    }
}

.ms-content-block.no-image {
    min-height: auto;
    .ms-content-block__image {
        display: none;
    }
}

.home-negative {
    background-color: #fff;
    margin-top: -100px;
    margin-bottom: 40px;
    padding-top: 10px;
    max-width: 620px;
    .ms-content-block__image {
        width: 100%;
        img {
            margin: 0 auto;
        }
    }
    .col-md-6 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

.home-negative.double-frag {
    max-width: 1260px;
    .col-md-6 {
        @media screen and (min-width: $screen-md) {
            flex: 0 0 50%;
        }
        .col-md-6 {
            flex: 0 0 100%;
        }
    }
    
}