// Cart item styles

.msc-cart-line {
    &__product-image {
        max-width: 80px;
        @media screen and (min-width: $screen-md) {
            max-width: 180px;
        }
    }
}

.ms-checkout__guided-card-title {
    font-weight: 400;
}

// #shipping_addressstate {
//     option[value='AE'],
//     option[value='AS'],
//     option[value='AK'],
//     option[value='AE'],
//     option[value='AP'],
//     option[value='FM'],
//     option[value='GU'],
//     option[value='HI'],
//     option[value='MH'],
//     option[value='MP'],
//     option[value='PR'],
//     option[value='PW'],
//     option[value='VI'] {
//         display: none;
//     }
// }

.msc-address-form__item-threeletterisoregionname {
    display: none;
}

.msc-cart-line__product-price {
    display: none;
    
}

.msc-cart-line__product-price, .msc-cart-line__product-savings {
    color: $accentColor;
    
    margin-left: 0;
    @media screen and (min-width: $screen-md) {
        padding-top: 10px;
        text-align: left;
    }
    @media screen and (min-width: $screen-lg) {
        padding-top: 0;
        text-align: right;
    }
    font-weight: 700;
    font-family: $bodyText;
}

.msc-cart-line__product-price-actual .msc-price__actual, .msc-cart-line__product-savings-actual .msc-price__actual {
    color: $accentColor;
    
    font-weight: 700;
    font-family: $bodyText;
}

.msc-cart-lines-group-wraper__bopis-heading {
    display: none !important;
}

.msc-cart-lines-item {
    margin: 0 !important;
    padding-top: 20px;
}


// 2022 styles

.ms-cart, .general-page {
    padding: 20px 0 40px;
}

.ms-cart .msc-cart-lines-group {
    padding-top: 0;
}

.ms-cart .msc-cart__heading {
    margin-bottom: 20px !important;
}

.msc-order-summary-wrapper {
    background-color: rgba($color: #efefef, $alpha: .3);
}

.ms-cart .msc-cart-lines-group-wraper {
    background-color: rgba($color: #efefef, $alpha: .3);
    
}
.msc-cart-lines-item {
    padding: 20px;
    border-bottom: 4px solid #fff;
}

.ms-cart {
    .msc-cartline-wraper, .msc-cart-lines-group {
        
        margin-bottom: 20px;
        margin-right: 0;
        @media screen and (min-width: $msv-breakpoint-l) {
            margin-bottom: 0;
            margin-right: 40px;
        }
        
    }
    .msc-cart-line__product-quantity .quantity .decrement,
    .msc-cart-line__product-quantity .quantity .increment {
        padding: 0;
        height: 48px;
        font-size: 16px;
        border: 1px solid #ccc;
        transition: background .3s ease;
    }
    .msc-cart-line__product-quantity .quantity .decrement::after,
    .msc-cart-line__product-quantity .quantity .increment::after {
        font-size: 12px;
        line-height: 0.4;
    }
    .msc-cart-line__product-quantity .quantity .decrement:hover, .msc-cart-line__product-quantity .quantity .decrement:focus,
    .msc-cart-line__product-quantity .quantity .increment:hover, .msc-cart-line__product-quantity .quantity .increment:focus {
        background: $accentColor;
    }
    .msc-cart-line__product-quantity .quantity .quantity-input {
        font-family: $bodyText;
        position: relative;
        top: 3px;
        border: 1px solid #ccc;
    }
}

.msc-cart-line__product-quantity-label {
    font-family: $bodyText;
}

.msc-cart-line {
    .msc-cart-line__product-image {
        max-width: none;
    }
}

.ms-cart {
    display: block;
    @media screen and (min-width: $screen-md) {
        display: flex;
    }
}

.msc-cart-line__content {
    @media screen and (min-width: $screen-md) {
        display: block;
    }
    @media screen and (min-width: $screen-lg) {
        display: flex;
    }
}

.msc-order-summary-wrapper {
    flex: 0 0 300px;
}

.msc-promo-code__input-box {
    font-family: $bodyText;
}

.msc-cart__btn-checkout, .msc-cart__btn-guestcheckout, .msc-promo-code__apply-btn {
    min-width: 100px
}

.ms-cart .msc-promo-code__input-box {
    height: 48px;
    border: 1px solid #ccc;
}

.msc-cart-line__remove-item::after, .msc-cart-line__add-to-order-template::after, .msc-cart-line__add-to-wishlist::after {
    content: $msv-Cancel;
    font-family: $msv-icon-font-family;
    font-weight: 700;
    margin-left: 5px;
}

.ms-cart .msc-cart-line__remove-item {
    text-decoration: none;
}

.msc-cart-line__add-to-wishlist, .msc-cart-line__add-to-wishlist:hover {
    text-decoration: none;
}

.msc-cart-line__product-discount {
    span:first-child {
        display: inline-block;
        margin-right: 5px;
    }
}



.msc-cart__empty-cart .msc-cart__btn-backtoshopping {
    min-width: 250px;
}




#main {
    .msc-cart__btn-checkout.msc-btn {
        background-color: rgba($color: #000, $alpha: 1) !important;
        border-color: #000 !important;
        color: #fff !important;
    }
    
    .msc-cart__btn-checkout.msc-btn:hover, .msc-cart__btn-checkout.msc-btn:focus {
        background-color: rgba($color: #000, $alpha: 0) !important;
        color: #000 !important;
    }
    .msc-cart-line__remove-item, .msc-cart-line__add-to-order-template, .msc-cart-line__add-to-wishlist {
        border: none !important;
    }
    .msc-cart-line__remove-item.msc-btn:hover, .msc-cart-line__remove-item.msc-btn:focus, .msc-cart-line__add-to-order-template.msc-btn:hover, .msc-cart-line__add-to-order-template.msc-btn:focus, .msc-cart-line__add-to-wishlist.msc-btn:hover, .msc-cart-line__add-to-wishlist.msc-btn:focus {
        background: none !important;
        color: #000 !important;
    }


    .msc-cart__btn-addcarttotemplate.msc-btn, .msc-cart__btn-backtoshopping.msc-btn {
        font-size: 16px;
        width: 100%;
    }

    .msc-add-line-to-template__button.msc-btn, .add-selected-to-bag.msc-btn, .remove-selected.msc-btn {
        border: none;
    }

    .ms-order-template-table-line__product-remove-button.msc-btn {
        position: relative;
        top: 1px;
        margin-left: 6px;
    }

}


.ms-cart {
    .msc-add-to-wishlist, .msc-cart-line__add-to-order-template {
      border-color: transparent;
      height: auto;
      width: auto;
      background: none;
      display: block;
      margin-top: 10px;
      text-decoration: none;
    }
  
    .msc-cart-line__remove-item {
      display: block;
      margin-top: 10px;
      
    }
    .msc-cart-line__add-to-wishlist::after {
      font-family: "Font Awesome 5 Free";
      content: $msv-Heart;
      margin-left: 10px;
      border: none;
    }

    .msc-cart-line__add-to-order-template::after {
        font-family: "Font Awesome 5 Free";
        content: $msv-file;
        margin-left: 10px;
        border: none;
    }
  
    .msc-cart-line__remove-item,
    .msc-cart-line__add-to-wishlist {
      height: auto;
    }
  }

  .msc-cart-line__product-price, .msc-cart-line__product-savings {
    //width: 100%;
    margin-left: 0;
    @media screen and (min-width: $screen-md) {
        padding-top: 10px;
        text-align: left;
    }
    @media screen and (min-width: $screen-lg) {
        padding-top: 0;
        text-align: right;
    }
    font-weight: 700;
}

#main .msc-btn.msc-cart-line__remove-item {
    text-transform: none !important;
}

.msc-cart-line__remove-item, .msc-cart-line__add-to-order-template, .msc-cart-line__add-to-wishlist {
    font-family: $bodyText;
}


.msc-modal .msc-lines-added-to-order-template-dialog__dialog__view-order-template-button {
    background-color: rgba($color: #000, $alpha: 1) !important;
    border-color: #000 !important;
    color: #fff !important;
}

.msc-modal .msc-lines-added-to-order-template-dialog__dialog__view-order-template-button:hover, .msc-modal .msc-lines-added-to-order-template-dialog__dialog__view-order-template-button:focus {
    background-color: rgba($color: #000, $alpha: 0) !important;
    color: #000 !important;
}

.msc-modal .msc-lines-added-to-order-template-dialog__dialog__continue-shopping {
    background-color: rgba($color: #000, $alpha: 0) !important;
    color: #000 !important;
}

#main .msc-btn.msc-add-line-to-template__button:hover, #main .msc-btn.add-selected-to-bag:hover, #main .msc-btn.remove-selected:hover, #main .msc-btn.msc-add-line-to-template__button:focus, #main .msc-btn.add-selected-to-bag:focus, #main .msc-btn.remove-selected:focus {
    background-color: transparent !important;
    color: #000 !important;
}

.ms-order-template__heading {
    height: auto;
}

.ms-checkout-customer-account__order-total-section {
    position: relative;
    overflow: hidden;
}

.ms-checkout-customer-account__order-total-section::before {
    position: absolute;
    content: "Current order total minus shipping";
    left: 0;
}

.ms-checkout-customer-account__order-total-label {
    position: absolute;
    left: -999em;
}