// Checkout styles

.ms-checkout__guided-card-title-step {
    width: auto;
    margin-right: 10px;
    box-sizing: border-box;
}

.msc-address-form__input, .msc-address-form__dropdown {
    border: 1px solid #000;
}

.msc-cart-line__product-price .msc-price__actual::after {
    content: " : Total Price";
}

.msc-cart-line__product-unit-price .msc-price__actual::after {
    content: " : Unit Price";
}

.ms-checkout__line-items-header .ms-checkout__line-items-edit-cart-link, .ms-checkout__guided-card-btn-edit {
    text-decoration: none;
    height: auto;
    padding: 6px 10px;
    font-weight: normal;
}


.ms-checkout-customer-account__form-edit {
    background: #efefef;
    padding: 20px;
    margin-top: 20px;
    border-radius: 4px;
    .ms-checkout-customer-account__account-name {
        padding-top: 0;
    }
}

.ms-checkout-customer-account__credit-details-section {
    padding: 20px;
    background: #fff;
}

.ms-checkout-customer-account__account-credit-current-order-preview {
    margin-top: 0;
}

.ms-checkout-customer-account__credit-details-section {
    width: 100%;
    padding: 10px;
    background: #fff;
    border-radius: 4px;
    margin-top: 10px;
}

.ms-checkout-customer-account__account-credit-current-order-icon::after {
    margin-right: 10px;
}

.ms-checkout-customer-account__btn-pay {
    margin-top: 20px;
    margin-left: 0;
    visibility: hidden;
    position: relative;
}

.ms-checkout-customer-account__btn-pay::after {
    
    color: #fff;
    padding: 12px 20px;
    content: "Proceed";
    visibility: visible;
    position: absolute;
    top: 0;
    left: 0;
    background: #000;
}


.ms-checkout__line-items-delivery-group .msc-cart-line__content .msc-cart-line__product-price {
    width: auto;
}

.checkout-page {
    h2 {
        font-size: 1.5em !important;
        font-weight: normal;
        @media screen and (min-width: $screen-md) {
            font-size: 2em !important;
        }
    }
}

.ms-checkout__side-control-first .ms-checkout__btn-keep-shopping {
    text-decoration: none;
}

.msc-cart-line__product-price .msc-price__actual, .msc-cart-line__product-savings .msc-price__actual {
    font-family: $bodyText;
}

.msc-price {
    font-size: 16px;
}


.ms-checkout-customer-account__btn-pay {
    color: #000;
    border: 1px solid #000;
}

// Letian's Order Reference Styling. 

.lli-checkout-additional_info__input {
    input {
        color: #000;
        border: 1px solid #000;
        border-radius: 2px;
        height: 48px;
    }
}

.lli-checkout-additional_info__order-reference-input-text, .lli-checkout-additional_info__order-notes-input-text  {
    margin-bottom: 20px;
}

.react-datepicker__input-container {
    input {
        height: calc(1.5em + .75rem + 2px);
        padding: .375rem .75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #000;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #000;
        border-radius: .25rem;
        transition: border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
        display: inline-block;
        //margin-left: 10px;
    }
}

.lli-checkout-additional-info {
    label {
        margin-right: 10px;
    }
}

#main .msc-cart__btn-addcarttotemplate.msc-btn {
    height: auto;
}

.ms-checkout-guest-profile__input-text {
    color: #000;
    border: 1px solid #000;
    border-radius: 2px;
    height: 48px;
}


// hide Same as shipping address
.ms-checkout-billing-address__shipping-address-label {
    position: absolute;
    left: -9999em;
}

.ms-checkout-terms-and-conditions__container {
    margin-bottom: 20px;
}

// checkout overrides 

.ms-checkout-shipping-address .msc-address-detail, 
.ms-checkout-delivery-options__description, 
.ms-checkout-delivery-options__price, 
.ms-checkout-guest-profile__selected-email,
.ms-checkout-billing-address__heading, 
.ms-checkout-billing-address .msc-address-detail,
.ms-checkout-customer-account__input-label, 
.msc-order-summary__line-total, 
.msc-order-summary__items {
    font-family: $bodyText;
    font-size: inherit;
    line-height: 1.4;
}