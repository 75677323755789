.msc-invoices-list__container {
    .btn {
        background-color: rgba(0,0,0,0);
        border-color: #000;
        font-family: "Montserrat",sans-serif;
        text-transform: uppercase;
        color: #000;
        transition: all .3s ease;
        height: auto;
        min-height: 48px;
        border-radius: 0;
        line-height: 1.1;
    }
    .btn:hover {
        background-color: rgba(0,0,0,1);
        color: #fff;
    }
}

.msc-invoices-list__container__content__table__row__requestInvoiceButton {
    display: none;
}