// I do not know why... I'm confused as well as to why this is display: none; It makes no sense.

.ms-header .ms-header__account-info {
    display: block;
}

.ms-header {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 0 !important;
    background: #000;
    border: none;
    //border-bottom: 1px solid #e4e4e4;
    max-height: 59px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
}

#renderPage {
    > header {
        height: 48px;
    }
    
}



.ms-header__container {
    padding-bottom: 0;
    .inside-xxl {
        position: relative;
        z-index: 10;
    }
    > .inside-xxl {
        @media screen and (max-width: $msv-breakpoint-l) {
            padding: 0 8px 0 0;
        }
    }
}

.base-logo {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 13.5px 0;

    @media screen and (min-width: $msv-breakpoint-l) {
        max-height: 58px;
        flex: 0 0 180px;
        display: block;
        padding: 10px 20px 10px 0;
    }

    .ms-header__logo {
        flex: 0 0 160px;
    }
}

.base-other {
    display: none;
    @media screen and (min-width: $msv-breakpoint-l) {
        display: flex;
        max-height: 58px;
    }
}

.ms-header .ms-header__preferred-store-btn,
.ms-header .ms-search__icon,
.ms-header .ms-header__signin-button,
.ms-header .msc-wishlist-icon,
.ms-header .msc-cart-icon,
.ms-header .ms-header__profile-button {
    font-family: $bodyText;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 0.9em;
}

.ms-header .ms-header__account-info .ms-header__signin-button {
    font-weight: 400;
    font-size: 0.8em;
    @media screen and (min-width: $msv-breakpoint-l) {
        background-color: #fff;
    }
}

.ms-search__icon {
    width: auto;
}
.ms-search__icon-text {
    display: none;
}

.ms-header .ms-header__nav-icon {
    border-radius: 0;
    background: transparent !important;
    color: #fff;
    width: 48px;
    box-sizing: border-box;
    @media screen and (min-width: $msv-breakpoint-l) {
        width: auto;
    }
    span {
        display: none;
        @media screen and (min-width: $msv-breakpoint-l) {
            display: inline-block;
        }
    }
   
}
.ms-header .ms-header__nav-icon[aria-expanded='true'] {
    background: #fff !important;
    color: #000;
    border-right: 4px solid transparent;
}
.ms-header .ms-header__nav-icon[aria-expanded='false'] span {
    color: #fff;
}
.ms-header .ms-header__nav-icon[aria-expanded='true'] span {
    color: #000;
}
// .ms-header .ms-header__nav-icon {
//     border: none !important;
// }
.ms-header .ms-header__nav-icon::before {
    color: #fff;
    width: auto;
    @media screen and (min-width: $msv-breakpoint-l) {
        padding-right: 10px;
    }
}
.ms-header .ms-header__nav-icon[aria-expanded='true']::before {
    color: #000;
}
.ms-header__divider {
    background: #fff;
    height: 20px;
    margin-left: 8px;
    margin-right: 8px;
    width: 1px;
}

.connellySvg {
    width: 130px;
    margin-left: 8px;
    @media screen and (min-width: $msv-breakpoint-l) {
        margin-left: 15px;
    }
    .st0 {
        fill: #fff;
    }
}

.ms-header .ms-search__icon {
    border: 1px solid transparent;
}


.ms-search {
    display: block !important;
}

.ms-search__form-control, .ms-header .ms-search__form-cancelSearch {
    border-radius: 0;
}
.ms-search__form-control {
    max-width: none;
}

.ms-search__label.bx-hide {
    display: block;
}
.ms-search__form {
    position: absolute;
    left: 0;
    top: 48px;
    z-index: 100;
    background: rgba($color: #000000, $alpha: .8);
}

.ms-search__searchForm {
    max-width: 1415px;
    padding: 20px;
    box-sizing: border-box;
    margin: 0 auto;
    height: auto;
}

.ms-search__icon {
    padding-left: 5px;
    padding-right: 5px;
}
.ms-search__icon::before {
    content: '';
    background-image: url("../../../connelly/img/search.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 30px 30px;
    width: 30px;
    height: 30px;
}

.ms-search__form-submitSearch {
    flex: 0 0 48px;
}
.ms-search__form-submitSearch::before {
    content: '';
    background-image: url("../../../connelly/img/search.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 30px 30px;
    width: 48px;
    height: 48px;
}

.ms-header .ms-cart-icon {
    padding-left: 0;
    margin-left: -5px;
}
.ms-header .msc-cart-icon {
    width: auto;
    
    padding: 0;
    padding-left: 8px !important;
    
}
.ms-header .msc-cart-icon::before {
    content: '';
    background-image: url("../../../connelly/img/shopping-cart.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 30px 30px;
    width: 30px;
    height: 30px;
}

.ms-header .ms-header__account-info {
    margin-left: 0;
    
}
.ms-header .ms-header__account-info .ms-header__signin-button {
    background-color: transparent;
    padding-left: 5px;
    padding-right: 5px;
}

.ms-header .ms-header__signin-button .ms-header__signin-button-text {
    background-image: url("../../../connelly/img/user.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 30px 30px;
    width: 30px;
    height: 30px;
    span {
        position: absolute;
        left: -999em;
    }
}



.ms-header .ms-header__preferred-store-btn, .ms-header .ms-search__icon, .ms-header .ms-header__signin-button, .ms-header .msc-wishlist-icon, .ms-header .msc-cart-icon, .ms-header .ms-header__profile-button {
    color: #fff;
}


.connellySvg {
    svg {
        position: relative;
        top: 2px;
        width: 100%;
    }
}

.ms-cookie-compliance {
    position: fixed;
    z-index: 1000;
    bottom: 0;
    width: 100%;
}

.ms-cookie-compliance__accept-button {
    background-color: $b2bDkGray;
    border: 1px solid $b2bDkGray;
    color: #fff;
}

.ms-cookie-compliance__accept-button:hover, .ms-cookie-compliance__accept-button:focus {
    background-color: #000;
    border-color: #000;
    color: #fff;
}

.ms-cart-icon__flyout-container .msc-flyout-inner .ms-cart-icon__btn-checkout,
.ms-cart-icon__flyout-container .msc-flyout-inner .ms-cart-icon__btn-gotocart {
    background-color: $b2bDkGray;
    border: 1px solid $b2bDkGray;
    color: #fff;
}

.ms-cart-icon__flyout-container .msc-flyout-inner .ms-cart-icon__btn-gotocart:hover,
.ms-cart-icon__flyout-container .msc-flyout-inner .ms-cart-icon__btn-gotocart:focus {
    background-color: #000;
    color: #fff;
}




// new changes for logged in state.

.ms-header .ms-header__profile-button {
    background-image: url("../../../connelly/img/user.svg") !important;
    background-repeat: no-repeat !important;
    background-position: 2px center !important;
    background-size: 30px 30px !important;
    width: 55px;
    height: 48px;
    padding: 0 5px;
    background: #3E5C6B;
    span {
        position: absolute;
        background: #3E5C6B;
        top: 48px;
        min-width: 137px;
        right: 0;
        min-height: 48px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

.ms-header__profile-button::after {
    font-family: "Font Awesome 5 Free";
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    font-weight: 900;
    text-rendering: auto;
    line-height: 1;
    content: "";
    font-size: var(--msv-header-font-size);
    margin-left: 0;
    text-align: center;
    vertical-align: text-bottom;
    position: relative;
    right: -10px;
  }